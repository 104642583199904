import { isValidMail } from '../../../../../../Common/Resources/src_front/js/helper-functions/isValidMail';

export const initContactUsForm = (modal) => {
    // VARS
    const contactUsForm = document.querySelector('[data-contact-us-form]');
    if (!contactUsForm) return;
    const userName = contactUsForm.querySelector('[data-input-name]');
    const userEmail = contactUsForm.querySelector('[data-input-email]');
    const userMessage = contactUsForm.querySelector('[data-input-message]');
    const submit = contactUsForm.querySelector('[data-submit]');
    const fieldsSelector = {
        userName: '[data-input-name]',
        userEmail: '[data-input-email]',
        userMessage: '[data-input-message]',
    };
    const classShowErrorMessage = 'is-visible-error';
    let isValidForm = true;

    // LISTENERS
    submit.addEventListener('click', handleOnSubmit, false);

    userName.addEventListener('focus', handleOnFocusFieldUserName, false);

    userEmail.addEventListener('focus', handleOnFocusFieldUserEmail, false);

    userMessage.addEventListener('focus', handleOnFocusFieldUserMessage, false);

    // HANDLERS
    function handleOnSubmit(event) {
        validateUserName();
        validateUserEmail();
        validateUserMessage();
        event.preventDefault();

        if (isValidForm) {
            showSuccessfulSendModal();
            contactUsForm.reset();
        } else {
            isValidForm = true;
        }
    }

    function handleOnFocusFieldUserName() {
        hideErrorMessage(fieldsSelector.userName);
    }

    function handleOnFocusFieldUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);
    }

    function handleOnFocusFieldUserMessage() {
        hideErrorMessage(fieldsSelector.userMessage);
    }

    // FUNCTIONS
    function validateUserName() {
        hideErrorMessage(fieldsSelector.userName);

        if (userName.value.trim()) {
            hideErrorMessage(fieldsSelector.userName);
        } else {
            showErrorMessage(fieldsSelector.userName, 'required');
            isValidForm = false;
        }
    }

    function validateUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);

        if (!userEmail.value.trim()) {
            showErrorMessage(fieldsSelector.userEmail, 'required');
            isValidForm = false;
        } else if (!isValidMail(userEmail.value.trim())) {
            showErrorMessage(fieldsSelector.userEmail, 'invalid');
            isValidForm = false;
        } else {
            hideErrorMessage(fieldsSelector.userEmail);
        }
    }

    function validateUserMessage() {
        hideErrorMessage(fieldsSelector.userMessage);

        if (userMessage.value.trim()) {
            hideErrorMessage(fieldsSelector.userMessage);
        } else {
            showErrorMessage(fieldsSelector.userMessage, 'required');
            isValidForm = false;
        }
    }

    function hideErrorMessage(selector) {
        const field = document.querySelector(selector);
        const errors = field.closest('[data-input-wrapper]').querySelectorAll('[data-field-error]');

        field.classList.remove(classShowErrorMessage);
        [...errors].forEach((error) => {
            error.classList.remove(classShowErrorMessage);
        });
    }

    function showErrorMessage(selector, type) {
        const field = document.querySelector(selector);
        const error = field.closest('[data-input-wrapper]').querySelector(`[data-field-error="${type}"]`);

        field.classList.add(classShowErrorMessage);
        error.classList.add(classShowErrorMessage);
    }

    function showSuccessfulSendModal() {
        modal.open('success-send');
    }
};
