import { initScrollLocker } from './tools/scrollLocker';
import { initLazyLoadFonts } from './components/initLazyLoadFonts';
import { initCookieBanner } from './components/initCookieBanner';
import { initAutocompleteDevices } from './components/initAutocompleteDevices';
import { Hamburger } from './components/Hamburger';
import { initMobileFeatureMenu } from './components/initMobileFeatureMenu';
import { initDropdowns } from './components/initDropdowns';
import { initMobileMenu } from './components/initMobileMenu';
import { Modal } from './components/modal/Modal';
import { initForms } from './components/initForms';
import {
    initFacebookHacking,
    initHackHacking,
    initHackHackingStart,
    initHackHackingThanks,
    initHackHackingTwitter,
    initHackHackingTwitterStart,
    initMailboxHacking,
} from './components/modal/initModal';
import './components/faq-accordion';
import { initBannerTimer } from './components/initBannerTimer';
import { initStickyMenu } from './components/initStickyMenu';
import { initLangSwitcher } from './components/initLangSwitcher';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';
import { initEmailAutocompleteDropdown } from '../../../../Common/Resources/templates/components/EmailAutocompleteDropdown/initEmailAutocompleteDropdown';
import { initRippleAnimation } from '../../../../Common/Resources/templates/components/RippleAnimation/initRippleAnimation';
import { initPageLoader } from '../../../../Common/Resources/templates/components/PageLoader/initPageLoader';
import { initChunkPageBeforeCheckout } from './components/initChunkPageBeforeCheckout';
import { initSnapchatHacking, initSnapchatHackingProcess } from './pages/hack/initSnapchatHacking';
import { initInstagramHacking } from './pages/hack/initInstagramHacking';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initJivoChat } from './components/initJivoChat';

document.addEventListener('DOMContentLoaded', handleOnLoadPage, false);

// If the browser has cached the page
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        setTimeout(function () {
            if (window.pageLoader) {
                window.pageLoader.hide();
            }
        }, 500);
    }
});

function handleOnLoadPage() {
    initPageLoader();
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initChunkPageBeforeCheckout();
    initLazyLoadFonts();
    initHashEmail();
    initScrollLocker();
    const modal = new Modal();
    window.hamburger = new Hamburger();
    initAutocompleteDevices();
    initMobileFeatureMenu();
    initCookieBanner();
    initDropdowns();
    initMobileMenu();
    initForms(modal);
    initFacebookHacking(modal);
    initMailboxHacking(modal);
    initHackHacking(modal);
    initHackHackingStart(modal);
    initHackHackingThanks(modal);
    initSnapchatHacking(modal);
    initSnapchatHackingProcess(modal);
    initInstagramHacking(modal);
    initHackHackingTwitter(modal);
    initHackHackingTwitterStart(modal);
    initBannerTimer();
    initStickyMenu();
    initLangSwitcher();
    initEmailAutocompleteDropdown();
    initRippleAnimation();
    initJivoChat();
}
